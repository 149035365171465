import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: './home/home.module#HomePageModule'
  },
  { path: 'workout-options', loadChildren: './pages/workout-options/workout-options.module#WorkoutOptionsPageModule' },
  { path: 'core', loadChildren: './pages/core/core.module#CorePageModule' },
  { path: 'cardio', loadChildren: './pages/cardio/cardio.module#CardioPageModule' },
  { path: 'resistance', loadChildren: './pages/resistance/resistance.module#ResistancePageModule' },
  { path: 'workout', loadChildren: './pages/workout/workout.module#WorkoutPageModule' },
  { path: 'workout/:type/:rounds/:routines', loadChildren: './pages/workout/workout.module#WorkoutPageModule' },
  { path: 'timer', loadChildren: './pages/circuit/circuit.module#CircuitPageModule' },
  { path: 'hiit', loadChildren: './pages/hiit/hiit.module#HiitPageModule' },
  { path: 'cycle', loadChildren: './pages/cycle/cycle.module#CyclePageModule' },
  { path: 'countdown', loadChildren: './pages/countdown/countdown.module#CountdownPageModule' },
  { path: 'food-search', loadChildren: './pages/nutrient/food-search/food-search.module#FoodSearchPageModule' },
  { path: 'food-search/:foodId', loadChildren: './pages/nutrient/food-search/food-search.module#FoodSearchPageModule' },
  { path: 'food-compare', loadChildren: './pages/nutrient/food-compare/food-compare.module#FoodComparePageModule' },
  { path: 'nutrient-details', loadChildren: './pages/nutrient/details/details.module#DetailsPageModule' },
  { path: 'nutrient-details/:nutrientId', loadChildren: './pages/nutrient/details/details.module#DetailsPageModule' },
  { path: 'nutrients', loadChildren: './pages/nutrient/nutrients/nutrients.module#NutrientsPageModule' },
  { path: 'circuit', loadChildren: './pages/progressive/progressive.module#ProgressivePageModule' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
