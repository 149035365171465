import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

// http://meumobi.github.io/ionic/pwa/2018/04/12/using-analytics-ionic-app-pwa-native.html
// import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  menuEnabled: boolean = true;

  public ga_analytics_tracking_id = 'UA-136244784-1';

  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'Workouts',
      url: '/circuit',
      icon: 'rocket'
    },
    {
      title: 'Core',
      url: '/core',
      icon: 'body'
    },
    /*{
      title: 'Cardio',
      url: '/cardio',
      icon: 'bicycle'
    },
    {
      title: 'Resistance',
      url: '/resistance',
      icon: 'walk'
    },*/
    {
      title: 'Boxing/MMA',
      url: '/timer',
      icon: 'alarm'
    },
    {
      title: 'HIIT',
      url: '/hiit',
      icon: 'flash'
    },

    /*{
      title: 'Endless Core',
      url: '/cycle',
      icon: 'body'
    },*/
    {
      title: 'Countdown',
      url: '/countdown',
      icon: 'alarm'
    }
    /*{
      title: 'Food Search',
      url: '/food-search',
      icon: 'cart'
    },
    {
      title: 'Food Compare',
      url: '/food-compare',
      icon: 'shuffle'
    },
    {
      title: 'Nutrients',
      url: '/nutrients',
      icon: 'flask'
    }*/
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    // public ga: GoogleAnalytics
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();


      /*this.ga.startTrackerWithId(this.ga_analytics_tracking_id)
        .then(() => {
          console.log('Google analytics is ready now');
          //the component is ready and you can call any method here
          this.ga.debugMode();
          this.ga.setAllowIDFACollection(true);
        })
        .catch(e => console.log('Error starting GoogleAnalytics', e));
*/

    });
  }
  hideMenu() {
    this.menuEnabled = false;
  }

  showMenu() {
    this.menuEnabled = true;
  }
}
